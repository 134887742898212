import { useRef } from "react";
import { InputFileProps } from "../../../types/common.types";
import classes from "./FileSelect.module.css";

export const FileSelect = ({ onFilesSelected, disabled, acceptFileExtensionList, text }: InputFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  function files(files: FileList | null) {
    if (files) {
      const split = files[0].name.split(".");
      const ext = split[split.length - 1];
      if (acceptFileExtensionList.includes(ext)) {
        onFilesSelected(files);
      }
    }
  }

  return (
    <div>
      {disabled && (
        <div className={classes.disabled} onClick={showFileDialog}>
          {text}
        </div>
      )}
      {!disabled && (
        <div>
        <input
            style={{ display: "none" }}
            ref={inputFileRef}
            type="file"
            multiple={false}
            onChange={(e) => {
              files(e.target.files);
              //console.log(e.target.files);
            }}
          />
          <div className={classes.box} onClick={showFileDialog}>
            {text}
          </div>
        </div>
      )}
    </div>
  );
};
