import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { Button } from "../../../ui/button/Button";
import { OrganizationPrincipalHeaderItem } from "../../../ui/organization/principal/OrganizationPrincipalHeaderItem";
import { OrganizationPrincipalList } from "../../../ui/organization/principal/OrganizationPrincipalList";
import classes from "./HomePrincipalPage.module.css";
import trainingListOrganizationService from "../../../../services/training/training.list.organization.service";
import trainingFileService from "../../../../services/training/training.file.service";
import { ConverTo } from "../../../../types/training.types";
import principalOrganizationGetService from "../../../../services/principal/principal.organization.get.service";
import { FileSelect } from "../../../form/fileselect/FileSelect";
import principalUserAddService from "../../../../services/principal/principal.user.add.service";

export const HomePrincipalPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [showVRVideo, setShowVRVideo] = useState(false);
  const [showVRApplication, setShowVRAppplication] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  
  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  function addUser() {
    navigate("/user/add");
  }

  const handleFileRead = (file: File) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target && event.target.result && typeof event.target.result === 'string') {
        const fileContent = event.target.result;
        const linesArray = fileContent.split('\n');
        linesArray.map(async (e) => {
          //console.log(e + " " + organizationId);
          const expiresOn = new Date(0, 0, 0).toISOString().split("T")[0];
          const statusCode = await principalUserAddService.add(e, "1", "0", expiresOn, organizationId);
          if (statusCode === 200) {
            console.log("bulk create user " + e + " was successfull!");
            //navigate(-1);
          } else {
            //setError("an error occured");
            console.log("bulk update user error occured");
          }
          return e;
        });
      } else {
        console.error('File reading error: event.target.result is null');
      }
    };
    reader.readAsText(file);
  };

  function uploadUsersCSV(items: FileList) {
    console.log('add buld in csv');
    const file = items[0];
    console.log(file);
    handleFileRead(file);
    reloadPage();
  }

  useEffect(() => {
    if (userContext && userContext.user) {
      setOrganizationName(userContext.user.organizationname);
      setOrganizationId(userContext.user.organizationid);
      setShowVRVideo(userContext.user.can_access_video === 1 ? true : false);
      setShowVRAppplication(userContext.user.can_access_application === 1 ? true : false);
    }
  }, [userContext]);

  async function downloadApplication() {
    if (userContext && userContext.user) {
      const item = await principalOrganizationGetService.get(userContext.user.organizationid);
      navigate("/azure/storage/principal/download/application", { state: item });
    }
  }

  function downloadTraininglog() {
    if (userContext && userContext.user) {
      trainingListOrganizationService.list(userContext.user.organizationid).then((res) => {
        trainingFileService.load(res, "traininglog");
        trainingFileService.sendAs(ConverTo.CSV); // ConverTo.JSON
      });
    }
  }

  async function editVideoSequence() {
    if (userContext && userContext.user) {
      const item = await principalOrganizationGetService.get(userContext.user.organizationid);
      navigate("/azure/storage/principal/manage/video", { state: item });
    }
  }

  function localOnClickUpload(items: FileList) {
    console.log(items);
    uploadUsersCSV(items);
  }
  
  return (
    <div className={classes.container}>
      <h1>{organizationName}</h1>
      <br />
      <div className={classes.gridcontainer}>
        <Button text="Add a user" onClick={addUser} />
        <FileSelect
          acceptFileExtensionList={["csv"]}
          onFilesSelected={function (items: FileList): void {
            localOnClickUpload(items);
          }}
          text={"Bulk upload users"}
          disabled={false}
        />
        {showVRApplication && <Button text="Download application" onClick={downloadApplication} />}
        {showVRVideo && <Button text="VRguide video" onClick={editVideoSequence} />}
        <Button text="Download training log" onClick={downloadTraininglog} />
      </div>
      <br />
      <br />
      <OrganizationPrincipalHeaderItem />
      <OrganizationPrincipalList />
    </div>
  );
};
